import React from 'react';
import { Link } from 'gatsby';
import Main from '../components/Main';
import Button from '../components/Button';
import LogoAbus from '../images/abus.png';
import LogoBricard from '../images/bricard.jpg';
import LogoHeracles from '../images/heracles.jpg';
import LogoHeraclesCropped from '../images/heracles-cropped.png';
import LogoMetalux from '../images/metalux.png';
import LogoReelax from '../images/reelax.png';
import LogoVachette from '../images/vachette.png';

const Item = ({ logo, heading, caption }) => (
  <div className="border border-slate-200 hover:border-slate-300 p-4 mb-6">
    <img src={logo} className="object-cover h-32 mx-auto block mb-8" />
    <p className="text-center mb-4">
      <strong className="text-2xl">{heading}</strong>
    </p>
    <p className="text-center mb-4">{caption}</p>
  </div>
 );

export default function Marques() {
  return (
    <Main>
      <div className="bg-white pt-12 pb-20 px-8 md:px-0">
        <div className="container mx-auto">
          <p className="mb-12">
            <strong className="font-semibold text-3xl">Nos marques</strong>
          </p>
          <div className="flex mb-12">
            <span className="text-5xl text-slate-400 mr-2">&ldquo;</span>
            <div className="text-xl italic">
              <p className="mb-2">Nous sommes fournisseur spécialisé des marques suivantes.</p>
              <p className="mb-2">Demandez nous conseils, nos fournisseurs disposent d’une large gamme de produits.&nbsp;</p>
            </div>
          </div>
          <div className="md:grid grid-cols-2 gap-x-6 mb-16">
            <Item logo={LogoHeracles} heading="Heracles" caption="Marque d’outillage professionnelle, fabriquant de serrures, cylindres, systèmes et solutions de Sécurité, contrôle d’accès ..." />
            <Item logo={LogoAbus} heading="Abus" caption="Fabricant allemand spécialisé dans les matériels de sécurité préventifs." />
            <Item logo={LogoBricard} heading="Bricard" caption="Entreprise française de fabrication de serrures fondée en 1782" />
            <Item logo={LogoVachette} heading="Vachette" caption="N°1 français des systèmes de sécurité et de la quincaillerie, Vachette est reconnue sur le marché pour la qualité et la technicité de ses produits." />
            <Item logo={LogoMetalux} heading="Metalux" caption="Metalux propose des serrures et des pièces détachées pour toutes les portes, intérieures et extérieures." />
            <Item logo={LogoReelax} heading="Reelax" caption="REELAX est une marque de serrure du groupe TORDJMAN Métal, leader en France des portes blindées et blindages de porte." />
          </div>
          <hr className="h-1 rounded-full bg-slate-200 w-64 mx-auto mb-16" />
          <div className="md:flex items-center justify-around mb-16">
            <div className="text-3xl mb-4">
              Garantie
              <br />
              & Conseils
            </div>
            <div className="md:w-2/5 text-xl mb-4">
              Tous nos produits sont garanties dont la durée dépend de la marque et du type de produit. Pour une information, l’utilisation ou les conseils sur un produit, une marque, nous serons présent pour y répondre.
            </div>
            <Button as={Link} to="/contact">Plus d'infos</Button>
          </div>
        </div>
      </div>
    </Main>
  )
}
